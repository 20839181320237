<!-- 
    页面 设备管理主流程
-->
<template>
  <div id="collect-equipment-page">
    <!-- <vx-card class="mb-5">
      <h5>当前社区:<span class="text-step">{{ this.$store.state.auth.space.space_name || "无" }}</span></h5>
    </vx-card> -->

    <vx-card class="mb-3">
      <!-- 进度调 -->
      <h6 class="text-steps pb-5">
        请按以下步骤解锁全部流程<span class="text-step pl-2">当前激活: {{ stepActive }}</span>
      </h6>
      <el-steps :active="stepActive" space="300px">
        <el-step title="设备组总览" description="解锁下一步: 需新建任意种类的设备"></el-step>
        <el-step title="点位组总览" description="解锁下一步: 需新建任意种类的点位"></el-step>
        <el-step title="通行组总览" description="解锁所有功能: 需新建任意种类的通行组"></el-step>
        <el-step title="全部解锁" description="恭喜您已解锁所有功能"></el-step>
      </el-steps>
    </vx-card>

    <vx-card  class="mb-3">
      <!-- 主版页-->
      <el-row :gutter="18">
        <el-col :span="6" class="split-line">
          <h6 class="pb-2">设备总量</h6><span class="text-line">{{ deviceData.deviceCount || 0 }}</span>
        </el-col>
        <el-col :span="6" class="split-line">
          <h6 class="pb-2">未绑定/已绑定<span style="font-size:12px;padding-left:5px">(设备与点位)</span></h6>
          <span class="text-line">{{ deviceData.undeviceCount || 0 }} / {{ deviceData.binddeviceCount || 0 }}</span>
        </el-col>
        <el-col :span="6" class="split-line">
          <h6 class="pb-2">点位总量</h6><span class="text-line">{{ deviceData.ioCount || 0 }}</span>
        </el-col>
        <el-col :span="6">
          <h6 class="pb-2">通行组总量</h6><span class="text-line">{{ deviceData.pgCount || 0 }}</span>
        </el-col>
      </el-row>
    </vx-card>

    <vx-card style="min-height: 530px">
      <!-- 快捷指令-->
      <h3>快捷指令</h3>
      <el-divider content-position="left">设备管理</el-divider>
      <el-row :gutter="18" style="min-height: 110px">
        <el-col v-for="(item, index) in deviceTypeList" class="mb-1" :key="index" :span="6">
          <el-card :body-style="{ padding: '0px' }">
            <div class="set_4_button4 raised hoverable" @click="toDeviceView(item.type)">
              <div class="anim"></div>
              <div class="shadow" style="padding: 12px">
                <div class="clearfix card-list">
                  <span :title="item.name" class="name">{{ item.name }}</span>
                  <span class="num">{{ item.count }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-divider content-position="left">点位管理</el-divider>
      <el-row :gutter="20" style="min-height: 110px">
        <el-col class="mb-1" v-for="(item, index) in inoutTypeList" :key="index" :span="4">
          <el-card :body-style="{ padding: '0px' }">
            <div class="set_4_button4 raised hoverable" :class="stepActive >= 2 ? '':'click-disable'" @click="stepActive >= 2  && toInoutView(item.type, item.name)">
              <div class="anim"></div>
              <div class="shadow" style="padding: 12px">
                <div class="clearfix card-list">
                  <span :title="item.name" class="name w-10/12 truncate">{{ item.name }}</span>
                  <span class="num">{{ item.count }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-divider content-position="left">通行组管理</el-divider>
      <el-row :gutter="20" style="min-height: 110px">
        <el-col class="mb-1" v-for="(item, index) in groupTypeList" :key="index" :span="4">
          <el-card :body-style="{ padding: '0px' }">
            <div class="set_4_button4 raised hoverable" :class="stepActive >= 4 ? '':'click-disable'"  @click="stepActive >= 4 && toGroupView(item.type, item.name)">
              <div class="anim"></div>
              <div class="shadow" style="padding: 12px">
                <div class="clearfix card-list">
                  <span :title="item.name" class="name">{{ item.name }}</span>
                  <span class="num">{{ item.count }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </vx-card>

  </div>
</template>

<script>
import VxCard from '../../../../components/vx-card/VxCard.vue';
const VxTour = () => import('@/components/VxTour.vue')

export default {
  components: { VxCard },
  data() {
    return {
      stepActive: 1,
      deviceData: {},
      // 枚举
      relationReturnDataAll: [],
      deviceTypeList: [],
      inoutTypeList: [],
      groupTypeList: []
    };
  },
  watch: {
    deviceData(val) {
      this.deviceTypeList[0]["count"] = val.cameraCount;
      this.deviceTypeList[1]["count"] = val.doorCount;
      this.deviceTypeList[2]["count"] = val.nbCount;
      this.deviceTypeList[3]["count"] = val.elevatorCount;
      this.deviceTypeList[4]["count"] = val.garbageCount;
      this.deviceTypeList[5]["count"] = val.healthCount;
      this.deviceTypeList[6]["count"] = val.gatewayCount;
      this.deviceTypeList[7]["count"] = val.otherdeivceCount;

      this.inoutTypeList[0]["count"] = val.carioCount;
      this.inoutTypeList[1]["count"] = val.faceioCount;
      this.inoutTypeList[2]["count"] = val.unitioCount;
      this.inoutTypeList[3]["count"] = val.wallioCount;
      this.inoutTypeList[4]["count"] = val.wallfaceioCount;
      this.inoutTypeList[5]["count"] = val.wallcarioCount;
      this.inoutTypeList[6]["count"] = val.multiuseioCount;
      this.inoutTypeList[7]["count"] = val.fireioCount;

      this.groupTypeList[0]["count"] = val.usepgCount;
      this.groupTypeList[1]["count"] = val.managepgCount;

      let isNullDeviceList = _.find(this.deviceTypeList, (o) => {return o.count > 0});
      let isNullInoutList  = _.find(this.inoutTypeList, (o) => {return o.count > 0});
      let isNulGroupList = _.find(this.inoutTypeList, (o) => {return o.count > 0});
      if(isNullDeviceList) {
        this.stepActive = 2;
        if (isNullInoutList) {
          this.stepActive = 3;
          if (isNulGroupList) {
            this.stepActive = 4;
          }
        }
      }
    },
    "$store.state.auth.space.space_code"(val) {
      this.stepActive = 1;
      this.getDeviceCount();
    },
    "relationReturnDataAll"(val) {
    
      let deviceTypeList =  _.find(val, { 'using_type': 'db/device/main_type' }).extend
      let inoutTypeList =  _.find(val, { 'using_type': 'db/area_in_out/space_type' }).extend
      let groupTypeList = _.find(val, { 'using_type': 'db/pass_group/group_type' }).extend

      deviceTypeList.map(item => Object.assign(item, {count: 0, type: item.type_name}));
      inoutTypeList.map(item => Object.assign(item, {count: 0, type: item.code, }));
      groupTypeList.map(item => Object.assign(item, {count: 0, type: item.code}));

      this.deviceTypeList = deviceTypeList;
      this.inoutTypeList = inoutTypeList;
      this.groupTypeList = groupTypeList;
    }
  },
  methods: {
    //  快捷方式-设备
    toDeviceView(type) {
      if (!this.$store.state.auth.space.space_name) {
        this.$message({message: "请先选择社区",type: "warning",duration: 1500,offset: 100});
        this.bus.$emit("openTour",'');
      } else {
        this.$router.push({ name: "equipmentList", params: { type: type } });
      }
    },
    //  快捷方式-点位
    toInoutView(type, name) {
      if (!this.$store.state.auth.space.space_name) {
        this.$message({message: "请先选择社区",type: "warning",duration: 1500,offset: 100});
        this.bus.$emit("openTour",'');
      } else {
        this.$router.push({ name: "inoutList", params: { type: type, name: name } });
      }
    },
    // 快捷方式-通行组
    toGroupView(type, name) {
      if (!this.$store.state.auth.space.space_name) {
        this.$message({message: "请先选择社区",type: "warning",duration: 1500,offset: 100});
        this.bus.$emit("openTour",'');
      } else {
        this.$router.push({ name: "groupList", params: { type: type, name: name  } });
      }
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        };
        let result = await this.$apis.ODC("获取关系内容", params);
        if (this.$apis.isOk(result)) {
          return  result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 设备种类列表
    async getDeviceTypeList() {
      let deviceTypeList = await this.getRelationReturnData("db/device/main_type");
      deviceTypeList.map(item => Object.assign(item, {count: 0, type: item.type_name}))
      this.deviceTypeList = deviceTypeList
    },
    // 点位种类列表
    async getInOutTypeList() {
      let inoutTypeList = await this.getRelationReturnData("db/area_in_out/space_type");
      inoutTypeList.map(item => Object.assign(item, {count: 0, type: item.code, }))
      this.inoutTypeList = inoutTypeList
    },
    // 通行组种类列表
    async getGroupTypeList() {
      let groupTypeList = await this.getRelationReturnData("db/pass_group/group_type");
      groupTypeList.map(item => Object.assign(item, {count: 0, type: item.code}))
      this.groupTypeList = groupTypeList
    },
    // 获取关系内容并直接返回 -> 一个一个传
    async getRelationReturnData(usingType) {
      try {
        let params = {
          usingTypes: [usingType]
        };
        let result = await this.$apis.ODC("获取关系内容", params);
        if (this.$apis.isOk(result)) {
          return  result.relationList[0].extend
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 获取图表数据
    async getDeviceCount() {
      try {
        let params = {
          village_code: this.$store.state.auth.space.space_code
        };
        let result = await this.$apis.ODC("获取设备管理数据", params);
        if (this.$apis.isOk(result)) {
          this.deviceData = result.deviceData;
        } else {
          this.deviceData = {}
        }
      } catch (err) {
        this.deviceData = {}
      }
    }
  },
  async created() {
    this.relationReturnDataAll = await this.getRelationReturnDataALL(["db/device/main_type","db/area_in_out/space_type","db/pass_group/group_type"]);
    // 获取图表数据
    await this.getDeviceCount();
  },
};
</script>

<style lang="scss" scope="scope">
#collect-equipment-page {
  .vx-card__body {
    padding: 1.0rem;
  }
  .set_4_button4 {
    background-color: rgba(var(--vs-primary), 1);
  }
  .card-list {
    .name {
      float: left;
      font-size: 16px;
      font-weight: 600;
      color: #FFF !important;
    }
    .num {
      float: right;
      color: #FFF !important;
    }
  }
  .el-step {
    .el-step__head.is-finish {
      color: rgba(var(--vs-primary), 1) !important;
      border-color: rgba(var(--vs-primary), 1) !important;
    }
    .el-step__title.is-finish {
      color: rgba(var(--vs-primary), 1) !important;
    }
    .el-step__description.is-finish {
      color: rgba(var(--vs-primary), 1) !important;
    }
  }
  .auto-height {
    height: calc(100vh - 250px);
    min-height: 850px;
  }
  .text-step {
    font-size: 1.0rem;
  }
  .text-grey {
    color: #c0c4cc;
    font-weight: 400 !important;
  }
  .text-line {
    font-size: 20px;
    font-weight: 500;
  }
  .split-line {
    border-right: 1px solid;
    border-color: rgba(184, 194, 204, var(--border-opacity)) !important;
  }
  .click-disable {
    pointer-events: none;
    background-color: rgba(206,206,206,0.8);
  }
}
</style>
